
@import url('https://fonts.cdnfonts.com/css/helveticaneuecyr-2');
@font-face{
    font-family:"helvetica";
    src:url(../public/fonts/Helvetica-Font/Helvetica.ttf) format("woff"),
    url(../public/fonts/Helvetica-Font/Helvetica.ttf) format("opentype"),
    url(../public/fonts/Helvetica-Font/Helvetica.ttf) format("truetype");
}

@font-face{
    font-family:"brown" ;
    font-weight: lighter;
    font-style: normal;
    src:url(../public/fonts/Brown-Font/BrownLight\ Regular.ttf) format("woff"),
    url(../public/fonts/Brown-Font/BrownLight\ Regular.ttf) format("opentype"),
    url(../public/fonts/Brown-Font/BrownLight\ Regular.ttf) format("truetype");
}

@font-face{
    font-family:"brown" ;
    font-weight: lighter ;
    font-style: italic;
    src:url(../public/fonts/Brown-Font/BrownLight\ Italic.ttf) format("woff"),
    url(../public/fonts/Brown-Font/BrownLight\ Italic.ttf) format("opentype"),
    url(../public/fonts/Brown-Font/BrownLight\ Italic.ttf) format("truetype");
}

@font-face{
    font-family:"brown" ;
    font-weight: bold;
    font-style: normal;
    src:url(../public/fonts/Brown-Font/Brown\ Bold.ttf) format("woff"),
    url(../public/fonts/Brown-Font/Brown\ Bold.ttf) format("opentype"),
    url(../public/fonts/Brown-Font/Brown\ Bold.ttf) format("truetype");
}

@font-face{
    font-family:"brown" ;
    font-weight: normal;
    font-style: italic;
    src:url(../public/fonts/Brown-Font/Brown\ Italic.ttf) format("woff"),
    url(../public/fonts/Brown-Font/Brown\ Italic.ttf) format("opentype"),
    url(../public/fonts/Brown-Font/Brown\ Italic.ttf) format("truetype");
}

body {
    font-family:'brown' ,sans-serif;
}

.helvetica{

}
.font-secondary{
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-weight: 300;
                                                
}

@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 500ms;
  }

.z-60{
    z-index: 60;
}

.collection {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: dense;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
    
    margin: 60px auto;
    /* max-width: 800px; */
}

.item {
    grid-column: span 1;
    grid-row: span 1;
    
    
}  

.item img {
    background-color: lightblue;
    display: block;
    height: 100%;
    min-height: 200px;
    width: 100%;
    object-fit: cover;

}
.item:nth-of-type(3n + 1){
    grid-column: span 2;
}